import { K as KeydownManager, q as classnames, M as MutationObserverSafe } from './MutationObserverSafe-7a5dacdc.js';
import { c as getComputedStyleSafe, a as getBody, N as NodeType, i as isSSR, g as getDocument, G as GLOBAL_EVENTS, t as isDefined } from './element-d46853d9.js';

const getDesignTokenValue = (name) => { var _a; return (_a = getComputedStyleSafe(getBody())) === null || _a === void 0 ? void 0 : _a.getPropertyValue(name); };
const createBEMElement = (element, parent = '') => [parent, element].filter(Boolean).join('__');
const createBEMModifier = (element, modifier) => [element, modifier].filter(Boolean).join('--');

const getAttributes = (element, omit = []) => {
  if (!(element && element.attributes)) {
    return {};
  }
  const omitThese = [...(omit || []), 'ref'];
  const attrs = element.getAttribute('class') ? { className: element.getAttribute('class') } : {};
  for (let i = 0; i < element.attributes.length; i++) {
    const attr = element.attributes.item(i);
    if (!omitThese.includes(attr.name)) {
      attrs[attr.name] = attr.value;
    }
  }
  return attrs;
};
const getAttribute = (element, attr) => {
  var _a, _b, _c;
  if (!(element && element.attributes)) {
    return '';
  }
  return attr === 'className' || attr === 'class'
    ? ((_a = element.attributes.getNamedItem('className')) === null || _a === void 0 ? void 0 : _a.value) || ((_b = element.attributes.getNamedItem('class')) === null || _b === void 0 ? void 0 : _b.value)
    : (_c = element.attributes.getNamedItem(attr)) === null || _c === void 0 ? void 0 : _c.value;
};
const removeAttributes = (element, attrs = []) => {
  if (!element) {
    return;
  }
  (attrs || []).forEach(attr => element.removeAttribute(attr));
};
const parseStyles = (styles) => {
  return typeof styles === 'string'
    ? (styles || '')
      .split(';')
      .filter(style => style.split(':')[0] && style.split(':')[1])
      .map(style => {
      const name = style.split(':')[0].trim();
      return [name.startsWith('--') ? name : name.replace(/-./g, c => c.substring(1).toUpperCase()), style.split(':')[1].trim()];
    })
      .reduce((styleObj, style) => (Object.assign(Object.assign({}, styleObj), { [style[0]]: style[1] })), {})
    : styles;
};

const DEFAULT_SLOT_WRAPPER_KEY = 's-dsw';
function DefaultSlotWrapper({ mappedTo } = {}) {
  return (proto, propertyKey) => {
    const { connectedCallback } = proto;
    proto.connectedCallback = function () {
      this.hostElement[DEFAULT_SLOT_WRAPPER_KEY] = () => {
        if (mappedTo) {
          return this[mappedTo];
        }
        else {
          const prop = this[propertyKey];
          return typeof prop === 'function' ? prop.current : prop;
        }
      };
      return connectedCallback === null || connectedCallback === void 0 ? void 0 : connectedCallback.call(this);
    };
  };
}

const getOriginalAttributeName = (attr) => `__${attr}`;
const getSlotNode = (childNodes, slotName) => {
  for (const childNode of childNodes) {
    if (childNode['s-sr'] && childNode['s-sn'] === slotName) {
      return childNode;
    }
    const _childNode = getSlotNode(childNode.childNodes, slotName);
    if (_childNode) {
      return _childNode;
    }
  }
  return null;
};
const updateNode = (slotNode, child, attribute, value) => {
  if ([NodeType.TEXT, NodeType.ELEMENT].includes(child.nodeType)) {
    const isSame = child.isSameNode(slotNode);
    if (isSame) {
      if (child.nodeType === NodeType.TEXT) {
        // text nodes don't have innerText or innerHTML attributes
        // fallback for text nodes is "textContent" attribute
        child.textContent !== value && (child.textContent = value);
      }
      else {
        child[attribute] !== value &&
          (child[attribute] = value);
      }
    }
    else {
      child.remove();
    }
  }
};
const patchHostAssignableAttribute = (host, attribute, prototype) => {
  const originalAttrName = getOriginalAttributeName(attribute);
  const descriptor = Object.getOwnPropertyDescriptor(prototype, attribute);
  Object.defineProperty(host, originalAttrName, descriptor);
  Object.defineProperty(host, attribute, {
    configurable: true,
    get() {
      var _a;
      const defaultSlotWrapper = (_a = host[DEFAULT_SLOT_WRAPPER_KEY]) === null || _a === void 0 ? void 0 : _a.call(host);
      const slotNode = getSlotNode((defaultSlotWrapper === null || defaultSlotWrapper === void 0 ? void 0 : defaultSlotWrapper.childNodes) || this.childNodes, '');
      if (slotNode) {
        return slotNode[slotNode.nodeType === NodeType.TEXT ? 'wholeText' : attribute];
      }
      else {
        return this[originalAttrName];
      }
    },
    set(value) {
      var _a;
      const defaultSlotWrapper = (_a = host[DEFAULT_SLOT_WRAPPER_KEY]) === null || _a === void 0 ? void 0 : _a.call(host);
      const slotNode = getSlotNode((defaultSlotWrapper === null || defaultSlotWrapper === void 0 ? void 0 : defaultSlotWrapper.childNodes) || this.childNodes, '');
      if (slotNode) {
        const slotNodeParent = slotNode.parentElement;
        if (slotNodeParent.isSameNode(this)) {
          this[originalAttrName] = value;
        }
        else {
          const childNodes = [...Array.from(slotNodeParent.childNodes || [])];
          for (const child of childNodes) {
            updateNode(slotNode, child, attribute, value);
          }
        }
      }
      else {
        // we couldn't find a slot, but that doesn't mean that there isn't one. if this check ran before the DOM
        // loaded, we could have missed it. check for a content reference element on the scoped component and insert
        // it there
        this[originalAttrName] = value;
        const contentRefElm = this['s-cr'];
        if (contentRefElm) {
          this.insertBefore(contentRefElm, this.firstChild);
        }
      }
    }
  });
};
const patchHostDomBinding = (host, prototype, methodName) => {
  const originalMethodName = getOriginalAttributeName(methodName);
  const descriptor = Object.getOwnPropertyDescriptor(prototype, methodName);
  Object.defineProperty(host, originalMethodName, descriptor);
  Object.defineProperty(host, methodName, {
    configurable: true,
    value(node) {
      var _a;
      const slotName = (node === null || node === void 0 ? void 0 : node.slot) || '';
      const defaultSlotWrapper = (_a = host[DEFAULT_SLOT_WRAPPER_KEY]) === null || _a === void 0 ? void 0 : _a.call(host);
      if (!slotName && defaultSlotWrapper) {
        return defaultSlotWrapper[methodName](node);
      }
      else {
        return this[originalMethodName](node);
      }
    }
  });
};
const patchInsertBefore = (host, prototype) => {
  const methodName = 'insertBefore';
  const originalMethodName = getOriginalAttributeName(methodName);
  const descriptor = Object.getOwnPropertyDescriptor(prototype, methodName);
  Object.defineProperty(host, originalMethodName, descriptor);
  Object.defineProperty(host, methodName, {
    configurable: true,
    value(newNode, referenceNode) {
      const parent = referenceNode === null || referenceNode === void 0 ? void 0 : referenceNode.parentNode;
      if (parent && !this.isSameNode(parent)) {
        return parent[methodName](newNode, referenceNode);
      }
      else {
        return this[originalMethodName](newNode, referenceNode);
      }
    }
  });
};
const applyComponentPolyfills = (hostElement) => {
  if (hostElement) {
    try {
      patchHostAssignableAttribute(hostElement, 'textContent', Node.prototype);
      patchHostAssignableAttribute(hostElement, 'innerText', HTMLElement.prototype);
      patchHostDomBinding(hostElement, Node.prototype, 'appendChild');
      patchHostDomBinding(hostElement, Node.prototype, 'removeChild');
      patchInsertBefore(hostElement, Node.prototype);
      hostElement.setAttribute('polyfilled', '');
    }
    catch (e) {
    }
  }
};

const DEFAULT_COMPONENT_CONFIG = {
  disablePolyfills: false
};
class ComponentUtils {
  constructor(def, config = DEFAULT_COMPONENT_CONFIG) {
    this.def = def;
    this._loaded = false;
    this._config = {};
    this._config = Object.assign({}, config);
    this.setupDocumentMutationObserver().setupMethodWrappers();
  }
  isLoaded() {
    return this._loaded;
  }
  withKeydownManager(opts, target = this.def.hostElement) {
    let currentIndex = 0;
    if (this._keydownManager) {
      currentIndex = this._keydownManager.getCurrentIndex();
      this._keydownManager.detachEventListeners();
    }
    this._keydownManager = new KeydownManager(target, opts);
    this._keydownManager.setStartIndex(currentIndex);
    return this;
  }
  keydownManager() {
    return this._keydownManager;
  }
  getAttribute(attr) {
    return getAttribute(this.def.hostElement, attr);
  }
  getHostBemClasses(modifiers = {}) {
    var _a, _b;
    const currentClasses = this.getAttribute('class');
    const componentNameWithPrefix = ((_b = (_a = this.def) === null || _a === void 0 ? void 0 : _a.hostElement) === null || _b === void 0 ? void 0 : _b.tagName.toLowerCase()) || '';
    const existingClasses = (currentClasses || '').split(' ').filter(cls => !cls.startsWith(`${componentNameWithPrefix}--`));
    const stateClasses = Object.keys(modifiers)
      .filter(modifier => modifiers[modifier])
      .map(modifier => createBEMModifier(componentNameWithPrefix, modifier));
    return classnames([...new Set([createBEMElement(componentNameWithPrefix), ...existingClasses, ...stateClasses])]);
  }
  getChildBemClasses(element, parent = '', modifiers = {}, userDefinedClasses = '') {
    var _a, _b;
    const componentNameWithPrefix = ((_b = (_a = this.def) === null || _a === void 0 ? void 0 : _a.hostElement) === null || _b === void 0 ? void 0 : _b.tagName.toLowerCase()) || '';
    const parents = (parent || createBEMElement(componentNameWithPrefix))
      .split(' ')
      .filter(cls => cls.startsWith(componentNameWithPrefix) && !cls.includes('--'));
    const elementClasses = parents.map(parentClass => createBEMElement(element, parentClass));
    const states = Object.keys(modifiers).filter(modifier => modifiers[modifier]);
    const stateClasses = elementClasses.reduce((acc, elementClass) => [...acc, ...states.map(state => createBEMModifier(elementClass, state))], []);
    return classnames([...elementClasses, ...stateClasses, userDefinedClasses]);
  }
  getInitialTabIndex(defaultValue) {
    var _a;
    return (_a = this._initialTabIndex) !== null && _a !== void 0 ? _a : defaultValue;
  }
  setupDocumentMutationObserver() {
    if (!isSSR()) {
      const w = window;
      if (!w.documentMutationObserver) {
        w.documentMutationObserver = new MutationObserverSafe(records => { var _a; return (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new CustomEvent(GLOBAL_EVENTS.DOCUMENT_CHANGED, { detail: records })); });
        w.documentMutationObserver.observe(getDocument(), {
          childList: true,
          subtree: true
        });
      }
    }
    return this;
  }
  setupMethodWrappers() {
    return this.wrapOriginalMethod('disconnectedCallback', () => {
      var _a;
      this.isLoaded() && ((_a = this._keydownManager) === null || _a === void 0 ? void 0 : _a.detachEventListeners());
    })
      .wrapOriginalMethod('componentWillLoad', () => {
      var _a;
      const noPolyfill = (_a = this._config.disablePolyfills) !== null && _a !== void 0 ? _a : DEFAULT_COMPONENT_CONFIG.disablePolyfills;
      !noPolyfill && applyComponentPolyfills(this.def.hostElement);
      const tabIndex = this.getAttribute('tabindex');
      this._initialTabIndex = isDefined(tabIndex) ? +tabIndex : undefined;
    })
      .wrapOriginalMethod('componentDidLoad', () => {
      var _a;
      const focusPatchFn = (_a = this._config.patchFocusMethod) !== null && _a !== void 0 ? _a : DEFAULT_COMPONENT_CONFIG.patchFocusMethod;
      typeof focusPatchFn === 'function' && this.wrapHostElementMethod('focus', focusPatchFn);
      this._loaded = true;
    });
  }
  wrapOriginalMethod(name, wrapper) {
    const original = this.def[name];
    this.def[name] = () => {
      typeof wrapper === 'function' && wrapper();
      if (typeof original === 'function') {
        return original.call(this.def);
      }
      return undefined;
    };
    return this;
  }
  wrapHostElementMethod(name, wrapper) {
    const host = this.def.hostElement;
    const originalName = `__original_${String(name)}`;
    const descriptor = Object.getOwnPropertyDescriptor(host, name);
    descriptor && Object.defineProperty(host, originalName, descriptor);
    Object.defineProperty(host, name, {
      configurable: true,
      value() {
        typeof wrapper === 'function' && wrapper.call(host);
        if (typeof this[originalName] === 'function') {
          return this[originalName].call(host);
        }
        return this[originalName];
      }
    });
    return this;
  }
}

export { ComponentUtils as C, DEFAULT_SLOT_WRAPPER_KEY as D, DefaultSlotWrapper as a, getAttribute as b, getDesignTokenValue as c, createBEMElement as d, createBEMModifier as e, getAttributes as g, parseStyles as p, removeAttributes as r };
